import React from "react";

export const SidebarData = [
    {
        title: "Home Page",
        path: "/",
        className: "nav-text"
    },
    {
        title: "Dashboard",
        path: "/dashboard",
        className: "nav-text"
    },
    {
        title: "Contact",
        path: "/contact",
        className: "nav-text"
    },
    {
        title: "Policies",
        path: "/policies",
        className: "nav-text"
    }
];