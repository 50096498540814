import React, { useEffect, useState } from "react";
import "./PreviousCodeComponent.css";
import { collection, getDocs, getDoc, doc, query, orderBy } from "firebase/firestore";
import { db } from "../firebaseConfig";

const PreviousCodeComponent = ({ user }) => {

    const [codes, setCodes] = useState([]);
    const [username, setUserName] = useState(null);


    useEffect(() => {
        fetchUsername();
    }, []);

    useEffect(() => {
        if (username !== null) {
            fetchAllCodes();
        }
    }, [username]);

    const fetchUsername = async () => {
        try {

            const uid = user.uid;

            const fetchUsername = doc(db, "users", uid);
            const userNameRef = await getDoc(fetchUsername);
            if (userNameRef.exists()) {
                const username = userNameRef.data().username;
                setUserName(username);
            }
        } catch (error) {
            console.log("failed to get username", error);
        }

    }

    const fetchAllCodes = async () => {

        try {
            const codesRef = collection(db, "code_and_links", "users", username); // Reference to your collection

            const codesQuery = query(codesRef, orderBy("timestamp", "desc"));

            const querySnapshot = await getDocs(codesQuery); // Get all documents in the collection
            const fetchedCodes = [];

            querySnapshot.forEach((doc) => {
                // Extract code data from each document
                const data = doc.data();
                fetchedCodes.push({ code: data.code, link: data.link }); // Assuming "code" is the field name for the code
            });

            // Sort the links by timestamp

            setCodes(fetchedCodes);

        } catch (error) {
            console.error("Error fetching codes:", error);
        }
    };


    return (
        <div className="centering-parent">
            <div className="previous-code-div-container">
                <div className="previous-code-card">
                    <div className="title-container">
                        <h2 className="title-previous-code">Previous Codes</h2>
                    </div>
                    <ul className="previous-codes-ul">
                        {codes.map((item, index) => (
                            <li className="previous-codes-li" key={index}>
                                <span >{item.code}</span>
                                <a href={item.link} title={item.link.length > 30 ? item.link : null}>
                                    {item.link.length > 30 ? item.link.slice(0, 30) + '...' : item.link}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
};
export default PreviousCodeComponent;