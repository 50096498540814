import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar'
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import DashboardPage from './pages/DashboardPage';
import UsefulLinkPage from './pages/UseFulLinkPage';
import ContactPage from "./pages/ContactPage";
import PoliciesPage from './pages/PoliciesPage';

function App() {
    return (
        <>
        <Router>
            <NavBar />
            <Routes>
                <Route path="/" element={<AboutPage/>}/>
                <Route path="/dashboard" element={<DashboardPage/>}/>
                <Route path="/add-links" element={<UsefulLinkPage/>}/>
                <Route path="/contact" element={<ContactPage/>} />
                <Route path="/policies" element={<PoliciesPage/>} />
                <Route path="/u/:username" element={<HomePage />} />
            </Routes>
        </Router>
        </>
    );
}

export default App;
