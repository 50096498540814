import React, { useState, useEffect } from "react";
import GenerateNewCodeComponent from "../components/GenerateNewCodeComponent";
import PreviousCodeComponent from "../components/PreviousCodeComponent";
import LoginWithGoogleComponent from "../components/LoginWithGoogleComponent";
import RegisterUserDetailsComponent from "../components/RegisterUserDetailsComponent";
import { auth, db } from "../firebaseConfig";
import { getDoc, doc } from "firebase/firestore";
import './DashboardPage.css';
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";
import CustomAlert from "../components/CustomAlert";

const DashboardPage = () => {
    const [user, setUser] = useState(null);
    const [username, setUserName] = useState(null);
    const [loading, setLoading] = useState(true);
    const [customAlert, setCustomAlert] = useState(null);

    const navigate = useNavigate();

    const [isOnline, setIsOnline] = useState(navigator.onLine);


    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {

            if (user) {
                setLoading(true);
                setUser(user);
                fetchUsername(user);
            } else {
                setUser(null);
                setUserName(null);
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);


    const fetchUsername = async (user) => {

        if (!isOnline) {
            setCustomAlert("You are offline")
            return;
        }
        try {

            const uid = user.uid;

            const fetchUsername = doc(db, "users", uid);
            const userNameRef = await getDoc(fetchUsername);
            if (userNameRef.exists()) {
                const username = userNameRef.data().username;
                setUserName(username);
            }
        } catch (error) {
            console.log("failed to get username", error);
        } finally {
            setLoading(false);
        }

    }

    const handleDashboardLoginSuccess = (user) => {
        setLoading(true);
        setUser(user);
        fetchUsername(user);
    }

    const handleAddUsefulLinkButton = () => {
        navigate("/add-links")
    }

    const handleCloseAlert = () => {
        setCustomAlert(null);
    }


    return (
        <div className="div-conatainer">
            <div className="div-container-dashboard">
                {loading ? (
                    <Loading /> // Render the loading component when loading
                ) : user && username ? (
                    <div className="generate-newcode-previous-code-conatiner">

                        <div className="button-container">
                            <button
                                type="button"
                                className="add-useful-link-button"
                                onClick={handleAddUsefulLinkButton}>
                                Add Home Page Links
                            </button>
                        </div>

                        <GenerateNewCodeComponent user={user} />

                        <PreviousCodeComponent user={user} />
                    </div>
                ) : user && !username ? (
                    <RegisterUserDetailsComponent uid={user.uid} name={user.displayName} />
                ) : (
                    <LoginWithGoogleComponent onLoginSuccess={handleDashboardLoginSuccess} />
                )}
            </div>
            {customAlert && (
                <>
                    <div className="overlay"></div>
                    <CustomAlert alertText={customAlert} onClose={handleCloseAlert} />
                </>
            )}
        </div>
    )
};

export default DashboardPage;