import React, { useState,useEffect } from "react";
import "./RegisterUserDetailsComponent.css";
import { db, storage } from "../firebaseConfig";
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
import iconChooseImage from "../asset/icon_choose_image.png";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import imageCompression from "browser-image-compression";
import Loading from "./Loading";
import CustomAlert from "./CustomAlert";

const RegisterUserDetailsComponent = ({ uid, name }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [username, setUsername] = useState("");
    const [fullName, setFullName] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(null);
    const [customAlert, setCustomAlert] = useState(null);

    const isUsernameAvailable = async (username_string) => {
        if (username_string !== "") {
            try {
                const usernameRef = doc(db, "public", username_string);
                const docRef = await getDoc(usernameRef);

                if (docRef.exists()) {
                    setCustomAlert("Please choose another username")
                    return false;
                } else {
                    return true;
                }
            } catch (error) {
                setCustomAlert("Something went wrong")
                console.log(error);
                return false;
            }
        } else {
            return false;
        }
    }
    const uploadData = async () => {
        if (username !== "") {
            const isUsernameUnique = await isUsernameAvailable(username)
            if (isUsernameUnique) {

                setLoading(true);

                let profileImageUrl = "";
                if (selectedImage) {
                    profileImageUrl = await uploadImage(selectedImage);
                }

                try {
                    const updateRef = doc(db, "users", uid);
                    await updateDoc(updateRef, {
                        username: username,
                        profileUrl: profileImageUrl,
                        fullName: fullName
                    });

                    try {
                        const publicRef = doc(db, "public", username);
                        await setDoc(publicRef, {
                            username: username,
                            googleName: name,
                            timestamp: Date.now(),
                            profileUrl: profileImageUrl,
                            fullName: fullName
                        });
                    } catch (error) {
                        console.log(error);
                    }

                    if (location.pathname === "/dashboard") {
                        window.location.reload(); // Reload the page if current location is '/dashboard'
                    } else {
                        navigate("/dashboard"); // Navigate to '/dashboard' if not already there
                    }

                } catch (error) {
                    console.error("unable to update user.", error);
                } finally {
                    setLoading(false);
                }
            }
        } else {
            setCustomAlert("username cannot be empty")
        }
    };

    const uploadImage = async (file) => {

        let processedFile = file;
        const fileSizeKB = file.size / 1024;

        if (fileSizeKB > 200) {
            try {
                const options = {
                    maxSizeMB: 0.5, // Reduce the max size in MB for more compression
                    maxWidthOrHeight: 800, // Lower the max width or height for more compression
                    useWebWorker: true,
                    maxIteration: 10, // Increase the number of iterations for better compression
                    initialQuality: 0.7,    // Use web worker for compression
                }

                processedFile = await imageCompression(file, options);

            } catch (error) {
                return null;
            }
        }

        const StorageRef = ref(storage, `profile_images/${uid}/profile_picture`);
        const snapshot = await uploadBytes(StorageRef, processedFile);
        const downloadUrl = await getDownloadURL(snapshot.ref);

        setLoading(false);

        return downloadUrl;
    }


    const handleUsernameChange = (event) => {
        const trimmedUsername = (event.target.value).trim();
        setUsername(trimmedUsername);
    };

    const handleNameChange = (event) => {
        setFullName(event.target.value);

    };

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedImage(event.target.files[0]);
        }
    }

    const handleCloseAlert = () => {
        setCustomAlert(null);
    };

    return (
        <div className="div-container-register-user-details">
            <div className="register-user-details-card">
                <h3>Welcome {name}</h3>
                <input className="username-input"
                    type="text"
                    placeholder="your username"
                    value={username}
                    onChange={handleUsernameChange} />

                <input className="fullname-input"
                    type="text" placeholder="your full name"
                    value={fullName}
                    onChange={handleNameChange} />

                <img className="image-chooser-register-user-details" src={iconChooseImage} alt="choose image" />
                <input type="file" id="imageUpload" accept="image/*" onChange={handleImageChange}></input>

                {loading ? (
                    <Loading />
                ) : (
                    <button
                        className="submit-username-and-url-button"
                        type='submit'
                        onClick={uploadData}
                    >
                        Submit
                    </button>
                )}
            </div>
            {customAlert && (
                <>
                    <div className="overlay"></div>
                    <CustomAlert alertText={customAlert} onClose={handleCloseAlert} />
                </>
            )}
        </div>
    )
};

export default RegisterUserDetailsComponent;