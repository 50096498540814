import React from "react";
import { auth, db } from "../firebaseConfig";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import './LoginWithGoogleComponent.css';
import { setDoc, doc } from "firebase/firestore";

const LoginWithGoogleComponent = ({ onLoginSuccess }) => {

  const signInWithGoogle = async () => {
    const isEmbeddedBrowser = /Instagram|FBAN|FBAV/i.test(navigator.userAgent);

    if (isEmbeddedBrowser) {
      // Use an anchor tag with the download attribute to redirect the user
      // if opens in inbuilt browser then for sign in we need to take them to defaul browser
      const link = document.createElement('a');
      link.href = window.location.href;
      link.target = '_blank';
      link.download = '';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return;
    }

    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Save user data to Firestore
      await createUserDocument(user.uid, {
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        phoneNumber: user.phoneNumber
      });

      onLoginSuccess(user);

    } catch (error) {
      console.error("Error signing in with Google: ", error);
    }
  };

  const createUserDocument = async (uid, data) => {
    try {
      await setDoc(doc(db, "users", uid), data, { merge: true });
    } catch (error) {
      console.error("Error creating user document: ", error);
    }
  };



  return (
    <div className="div-container">
      <h3>Login with Google</h3>
      <button className="login-with-google-button" onClick={signInWithGoogle}>Sign in with Google</button>
    </div>
  );
};

export default LoginWithGoogleComponent;