import React, { useState, useEffect } from "react";
import "./UsefulLinkPage.css";
import CustomAlert from "../components/CustomAlert"
import { deleteDoc, doc, setDoc, getDoc, collection, getDocs,orderBy,query } from "firebase/firestore";
import { db, auth } from "../firebaseConfig";
import { MdDelete } from "react-icons/md";


const UsefulLinkComponent = () => {

    const [url, setUrl] = useState(null);
    const [title, setTitle] = useState(null);

    const [customAlert, setCustomAlert] = useState(null);
    const [extraButton, setExtraButton] = useState(null);
    const [linkToDelete, setLinkToDelete] = useState(null);

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true)

    const [username, setUsername] = useState(null);
    const [usefulLinks, setUsefulLinks] = useState([]);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    
    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);



    useEffect(() => {
        if (username !== null) {
            fetchAllLinks();
        }
    }, [username]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {

            if (user) {
                setLoading(true);
                setUser(user);
                fetchUsername(user);
            } else {
                setUser(null);
                setUsername(null);
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const fetchUsername = async (user) => {
        try {

            const uid = user.uid;

            const fetchUsername = doc(db, "users", uid);
            const userNameRef = await getDoc(fetchUsername);
            if (userNameRef.exists()) {
                const username = userNameRef.data().username;
                setUsername(username);
            }
        } catch (error) {
            console.log("failed to get username", error);
        } finally {
            setLoading(false);
        }

    }

    const handleInputTitle = (event) => {
        setTitle(event.target.value);
    }

    const handleInputUrl = (event) => {
        setUrl(event.target.value);
    }

    const uploadTitleAndLink = async () => {
        if(!isOnline){
            setCustomAlert("You are offline");
            return;
        }

        if (title === null) {
            setCustomAlert("title cannot be empty");
            return;
        }

        if (url === null) {
            setCustomAlert("url cannot be empty");
            return;
        }

        const isValidUrl = isValidHttpUrl(url);

        if (isValidUrl) {
            if (title !== null && url != null) {
                try {
                    await createUsefulLinkDocument(username, {
                        title: title,
                        url: url,
                        timestamp: Date.now()
                    });
                } catch (error) {

                }
            }
        } else {
            setCustomAlert("Inavlid link. Link must start with HTTP:// OR HTTPS://")
        }
    }

    const createUsefulLinkDocument = async (username, data) => {
        try {
            const docRef = doc(collection(db, "useful_links", username, "links"));
            await setDoc(docRef, data);
            setCustomAlert("Link added")
            setTitle("")
            setUrl("")
            window.location.reload();
        } catch (error) {
            console.log("unable to upload useful link to database", error);
        }
    }

    const fetchAllLinks = async () => {

        if(!isOnline){
            setCustomAlert("You are offline");
            return;
        }

        try {
            // Reference to the "links" subcollection under "useful_links/username"
            const linksCollectionRef = collection(db, "useful_links", username, "links");

            const linksQuery = query(linksCollectionRef, orderBy("timestamp", "desc"));

            // Get all documents in the "links" subcollection
            const querySnapshot = await getDocs(linksQuery);

            // Process the documents
            const linksData = [];
            querySnapshot.forEach((doc) => {
                // Push the document data along with the document ID
                linksData.push({ id: doc.id, ...doc.data() });
            });

            setUsefulLinks(linksData)
        } catch (e) {
            console.error("Error fetching data: ", e);
        }
    }

    const handleCloseAlert = () => {
        setCustomAlert(null);
        setExtraButton(null);
    };

    const handleYesButton = () => {
        deleteUsefulLink(linkToDelete)
        setExtraButton(null);
        setCustomAlert(null);
    }

    const handleSetLinkToDeleteAndShowAlert = (linkid) => {
    setLinkToDelete(linkid);
    setCustomAlert("You are deleting a link, are you sure?")
    isExtraYesAlertButton();
    }

    const isExtraYesAlertButton = () => {
        setExtraButton("Yes");
    }

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    const deleteUsefulLink = async (linkId) =>{
        await deleteDoc(doc(db,"useful_links", username, "links",linkId));
        setCustomAlert("link deleted");
        window.location.reload();
       
    }

    return (<div className="useful-link-page-container">
        <div className="div-add-usefull-link-card">
            <h3 className="text-useful-links">Add Useful Links</h3>
            <input className="input-add-link-title" type="text" placeholder="Title..." value={title} onChange={handleInputTitle } />
            <input className="input-add-link-url" type="text" placeholder="Link..." value={url} onChange={handleInputUrl} />
            <button className="button-add-link" type="button" onClick={uploadTitleAndLink}>Submit</button>
        </div>

        <div className="div-prevous-usefull-link-card">
            <h3 className="text-useful-links">Your Links</h3>
            <ul className="previous-links-ul">
                {usefulLinks.map((item, index) => (
                    <li className="previous-links-li" key={index}>
                        <a href={item.url}>{item.title}</a>
                        <MdDelete className="delete-button-usefull-link-page" onClick={() => handleSetLinkToDeleteAndShowAlert(item.id)} />
                    </li>
                ))}
            </ul>
        </div>
        {customAlert && (
            <>
                <div className="overlay"></div>
                <CustomAlert alertText={customAlert} onClose={handleCloseAlert} onYesClicked={handleYesButton} extraButton={extraButton}/>
            </>
        )}
    </div>)
}
export default UsefulLinkComponent;