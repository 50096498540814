import React, { useState } from 'react';
import './ContactPage.css';

const ContactPage = () => {

    return (
        <div className="contact-page-container">
            <h2>Contact Us</h2>
            <p>If you have any questions, feel free to reach out to us at:</p>
            <a href="mailto:jeeva.bio.app@gmail.com" className="contact-email">jeeva.bio.app@gmail.com</a>
        </div>
    );
};

export default ContactPage;
