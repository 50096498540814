import React from "react";
import "./WelcomePage.css";
import mainImage from "../asset/welcome_page_main_image.png"
import { useNavigate } from 'react-router-dom';

const WelcomePage = () => {
    const navigate = useNavigate();

    const handleRegisterClick = () => {
        navigate('/dashboard');
    };

    return (
        <div className="welcome-page">
            <h2 className="h2-text">Every Reel, A New<span style={{ color: "#FF7043" }}>Destination!</span></h2>
            <div className="image-container">
                <img src={mainImage} alt="demo_image"></img>
            </div>
            <h6>Stop updating your bio. Direct your audience with just one click.</h6>
            <button type="button" onClick={handleRegisterClick}>Get Started</button>
            <h3>How It Works</h3>
            <div className="div-how-it-works">
                <div className="card-how-it-works">
                    <div className="card-items">
                        <h6>STEP 1</h6>
                        <p>Add a unique code to your reel.</p>
                    </div>
                </div>
                <div className="card-how-it-works">
                    <div className="card-items">
                        <h6>STEP 2</h6>
                        <p>Users click on your bio and enter the code.</p>
                    </div>
                </div>
                <div className="card-how-it-works">
                    <div className="card-items">
                        <h6>STEP 3</h6>
                        <p>Voilà! Your users are redirected to your desired link.</p>
                    </div>
                </div>
            </div>
            <div className="features-div-welcome-page">
                <h3>Features</h3>
                <h5>Effortless Linking : Redirect users from your reels to your websites effortlessly. Say goodbye to bio link limitations.</h5>
                <h5>User-Friendly Redirects : Links open directly in apps for a seamless experience.</h5>
                <button type="button" onClick={handleRegisterClick}>Get Started</button>
            </div>
        </div>
    );
};
export default WelcomePage;