import React, { useEffect, useState } from "react";
import "./ProfileCard.css"
import { db } from "../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import profile_placeholder_image from "../../asset/profile_placeholder.png"

function ProfileCard() {
    const { username } = useParams();
    const [fullName, setFullName] = useState(null);
    const [profileUrl, setProfileUrl] = useState(null);

    useEffect(() => {
        const FetchUserDetails = async () => {
            if (username != null) {
                try {
                    const detailsRef = doc(db, "public", username);
                    const docSnap = await getDoc(detailsRef);
                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        setFullName(data.fullName);
                        setProfileUrl(data.profileUrl);
                    }
                } catch (error) {
                    console.log(error);
                }

            };
        }

        FetchUserDetails();
    }, [username]);

    return (
        <div className="profile-card">
            <img className="profile-card-image" src={profileUrl || profile_placeholder_image}></img>
            <h4 className="profile-card-text">{fullName || "@" + username}</h4>
        </div>
    );
};
export default ProfileCard;