import React, { useState, useEffect, useRef} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './NavBar.css';
import { HiOutlineBars3 } from "react-icons/hi2";
import { IoClose } from "react-icons/io5";
import { auth } from '../firebaseConfig';
import { IconContext } from 'react-icons';
import { SidebarData } from './SidebarData';
import { signOut } from 'firebase/auth';

const NavBar = () => {
    const [sidebar, setSidebar] = useState(false);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const navRef = useRef(null);
    const sidebarRef = useRef(null);


    const toggleSidebar = () => setSidebar(prevState => !prevState);

    const handleClickOutside = (event) => {
        if (
            sidebarRef.current && !sidebarRef.current.contains(event.target) &&
            navRef.current && !navRef.current.contains(event.target)
        ) {
            setSidebar(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // Listen for changes in authentication state
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setUser(user); // User is logged in
            } else {
                setUser(null); // User is logged out
            }
        });

        // Cleanup function
        return () => unsubscribe();
    }, []);

    const handleLogout = async () => {
        try {
            await signOut(auth);
            console.log("User logged out");
            setSidebar(false);
        } catch (error) {
            console.error("Error logging out: ", error);
        }
        setSidebar(false);
    };

    const handleLogin = () => {
        navigate("/dashboard"); 
        setSidebar(false);
    }

    return (
        <IconContext.Provider value={{ color: '#fff' }}>
            <div ref={navRef} className='navbar'>
            <h3 className='app-name'>Jeeva.bio</h3>
            
                <div className='menu-bars' onClick={toggleSidebar}>
                    <HiOutlineBars3 style={{color:'black'}}/>
                </div>
            </div>
            <nav ref={sidebarRef} className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                <ul className='nav-menu-items'>                    
                <li className='navbar-toggle'>
                    <h3 className='app-name'>Menu</h3>
                        <IoClose style={{color:'black'}} onClick={toggleSidebar}/>
                    </li>
                    {SidebarData.map((item, index) => (
                        <li key={index} className='nav-text' onClick={toggleSidebar}>
                            <Link to={item.path}>
                                {item.icon}
                                <span>{item.title}</span>
                            </Link>
                        </li>
                    ))}
                    {user ? (
                        <li className='nav-text logout'>
                            <button onClick={handleLogout} className='logout-button'>
                                Logout
                            </button>
                        </li>
                    ) : 
                    <li className='nav-text login'>
                            <button onClick={handleLogin} className='login-button'>
                                Login
                            </button>
                        </li>}
                </ul>
            </nav>
        </IconContext.Provider>
    );
};

export default NavBar;
