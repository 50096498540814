import React from "react";
import "./LinkCodeCard.css"
import { db } from '../../firebaseConfig';
import { doc, getDoc } from "firebase/firestore";
import { useState,useEffect } from "react";
import { useParams } from "react-router-dom";
import CustomAlert from "../CustomAlert"

function LinkCodeCard() {
    const [code, setCode] = useState("");
    const { username } = useParams();

    const [customAlert, setCustomAlert] = useState(null);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    
    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    async function fetchLink(code) {

        if(!isOnline){
            setCustomAlert("You are offline");
            return;
        }
        if (username == null) {
            setCustomAlert("Invalid username")
            return;
        }

        if (code.trim() === "") {
            setCustomAlert("Invalid code")
            return;
        }


        const userRef = doc(db, 'code_and_links', 'users', username, code);
        try {
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
                const link = docSnap.data().link;
                console.log("Fetched link data:", link);
                window.location.href = link;
            } else {
                setCustomAlert("Invalid code")
            }
        } catch (error) {
            setCustomAlert("Error fetching links");
        }
    }

    const handleCloseAlert = () => {
        setCustomAlert(null);
    };

    return (
        <>
            <div className="link-code-card">
                <h3 className="text-link-code-card">Find Your Links</h3>
                <input className="input-link-code-card" type="text" placeholder="enter reel code here..." autoFocus value={code} onChange={(e) => setCode(e.target.value)} />
                <button className="button-link-code-card" type="button" onClick={() => fetchLink(code)}>Submit</button>
                {customAlert && (
                    <>
                        <div className="overlay"></div>
                        <CustomAlert alertText={customAlert} onClose={handleCloseAlert} />
                    </>
                )}
            </div>
        </>
    );
};

export default LinkCodeCard;