import React, { useEffect } from "react";
import "./GenerateNewCodeComponent.css";
import { useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import CustomAlert from "./CustomAlert"


const GenerateNewCodeComponent = ({ user }) => {

    const [code, setCode] = useState(null);
    const [redirectLink, setRedirectLink] = useState("");
    const [username, setUserName] = useState(null);

    const [customAlert, setCustomAlert] = useState(null);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    
    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    useEffect(() => {
        fetchUsername();
    }, []);

    useEffect(() => {
        if (username !== null) {
            handleGenerateNewCodeClick();
        }

    }, [username]);

    const fetchUsername = async () => {

        if(!isOnline){
            setCustomAlert("You are offline");
            return;
        }
        try {

            const uid = user.uid;

            const fetchUsername = doc(db, "users", uid);
            const userNameRef = await getDoc(fetchUsername);
            if (userNameRef.exists()) {
                const username = userNameRef.data().username;
                setUserName(username);
            }
        } catch (error) {
            console.log("failed to get username", error);
        }

    }

    const generateUniqueCode = async () => {
        try {
            // Generate a random number between 1000 and 9999 (inclusive)
            let code = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;

            // Check if code already exists (replace with your logic)
            //const isCodeUnique = checkForExistingCode(code);
            const isCodeUnique = await checkForExistingCode(code.toString());

            // If not unique, regenerate
            if (!isCodeUnique) {
                return generateUniqueCode(); // Recursive call to try again
            }

            // Return the unique code
            return code.toString().padStart(4, '0');
        } catch (error) {
            console.log("unable to generate new code", error);
            return null;
        }
    };

    // Replace this with your logic to check if code exists in your system
    const checkForExistingCode = async (code) => {
        // Implement your logic to check database or other storage for existing codes
        // This example always returns false for simplicity
        const codeRef = doc(db, "code_and_links", "users", username, code);
        const docRef = await getDoc(codeRef);

        if (docRef.exists()) {
            return false;
        } else {
            return true;
        }

    }

    const handleGenerateNewCodeClick = async () => {
        try {
            const newCode = await generateUniqueCode();
            setCode(newCode);
        } catch (error) {
            console.log("handleGenerateNewCodeClick failed", error);
            setCode(null);
        }

    }

    const handleRedirectLinkChange = (event) => {
        setRedirectLink(event.target.value);
    };

    const uploadCode = async () => {

        if(!isOnline){
            setCustomAlert("You are offline");
            return;
            
        }

        if (redirectLink.trim() === "") {
            setCustomAlert("Please enter link")
            return;
        }

        if(code.trim() === null){
            setCustomAlert("please wait for code generation");
            return;
        }

        if (username.trim() === "") {
            setCustomAlert("Something went wrong")
            return;
        }

        const isLinkValid = isValidHttpUrl(redirectLink);

        if (isLinkValid) {
            try {
                await createCodeAndLinkDocument(code, username, {
                    code: code,
                    link: redirectLink,
                    timestamp: Date.now()
                });

            } catch (error) {
                console.log("Error creating code document.", error);
            }
        } else {
            setCustomAlert("Inavlid link. Link must start with HTTP:// OR HTTPS://")
            return;
        }

    }

    const createCodeAndLinkDocument = async (code, username, data) => {
        try {
            await setDoc(doc(db, "code_and_links", "users", username, code), data);
            setCustomAlert("Link added with code")
            setRedirectLink("")
            handleGenerateNewCodeClick();
            window.location.reload();
        } catch (error) {
            console.log("unable to upload code and link", error);
        }
    };

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    const handleCloseAlert = () => {
        setCustomAlert(null);
    };

    return (
        <div className="generate-new-code-div-container ">
            <div className="generate-new-code-card">
                <h5 className="text-generate-new-code">Your link</h5>
                <h5 className="text-generate-new-code">jeeva.bio/u/{username}</h5>
                <span className="bio-link-hint-text">(paste the above link to your bio)</span>
                <h3 className="text-generate-new-code">Your unique code: {code}</h3>
                <input className="input-generate-new-code" type="text" placeholder="your link" value={redirectLink} onChange={handleRedirectLinkChange} />
                <button className="button-generate-new-code" type="submit" onClick={uploadCode}>Link </button>
                {customAlert && (
                    <>
                        <div className="overlay"></div>
                        <CustomAlert alertText={customAlert} onClose={handleCloseAlert} />
                    </>
                )}
            </div>
        </div>
    )
};

export default GenerateNewCodeComponent;