import React, { useState,useEffect } from "react";
import "./OpenInAppCard.css";
import { useParams } from "react-router-dom";
import { db } from "../../firebaseConfig";
import { collection,getDocs } from "firebase/firestore";


function OpenInAppCard() {
    const { username } = useParams();
    const [usefulLinks, setUsefulLinks] = useState([])

    useEffect(() => {
        if (username !== null) {
            fetchAllLinks();
        }
    }, [username]);

    const fetchAllLinks = async () =>{
        try {
            // Reference to the "links" subcollection under "useful_links/username"
            const linksCollectionRef = collection(db, "useful_links", username, "links");
            
            // Get all documents in the "links" subcollection
            const querySnapshot = await getDocs(linksCollectionRef);
            
            // Process the documents
            const linksData = [];
            querySnapshot.forEach((doc) => {
              // Push the document data along with the document ID
              linksData.push({ id: doc.id, ...doc.data() });
            });
        
            setUsefulLinks(linksData)
          } catch (e) {
            console.error("Error fetching data: ", e);
          }
    }

    return (
        <div className="div-open-in-app-card">
            <ul className="useful-links-ul">
            {usefulLinks.map(item => (
                    <a key={item.id} href={item.url} className="list-card-open-in-app">
                        <li className="useful-links-li">
                            {item.title}
                        </li>
                    </a>
                ))}
            </ul>
            
        </div>
    );
};
export default OpenInAppCard;