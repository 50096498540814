import React from 'react';
import './PoliciesPage.css'; // Import your CSS file here

const PoliciesPage = () => {
  return (
    <div className="policies-container">
      <h1 className="section-title">Terms of Service</h1>
      <a className="policy-link" href='https://www.termsandconditionsgenerator.com/live.php?token=XuUhmLcBxUL36nvtR1C5NGVVXepUpu6D' target='_blank' rel='noopener noreferrer'>Click here for terms and conditions</a>

      <h1 className="section-title">Privacy Policy</h1>
      <a className="policy-link" href='https://www.termsfeed.com/live/2fc4fdaa-5a72-4d12-a296-63995f64cc75' target='_blank' rel='noopener noreferrer'>Click here for policy</a>
    </div>
  );
};

export default PoliciesPage;
