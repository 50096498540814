import React from "react";
import "./CustomAlert.css";

const CustomAlert = ({ alertText, onClose,onYesClicked, extraButton }) => {
    return (
        <div className="container-custom-alert">
            <p className="text-custom-alert">{alertText}</p>

            {extraButton ? (<div className="div-button-custom-alert">
                <button
                    type="button"
                    className="yes-button-custom-alert"
                    onClick={onYesClicked}
                >Yes</button>

                <button
                    type="button"
                    className="close-button-custom-alert"
                    onClick={onClose}
                >Close</button>

            </div>) : (<button
                type="button"
                className="close-button-custom-alert"
                onClick={onClose}
            >Close</button>)}
        </div>
    );
};

export default CustomAlert;
