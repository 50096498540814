import React from "react";
import "./HomePage.css"
import ProfileCard from '../components/cards/ProfileCard'
import LinkCodeCard from '../components/cards/LinkCodeCard'
import OpenInAppCard from "../components/cards/OpenInAppCard";


const HomePage = () => {
    return (
        <div className="div-home-page">
        <ProfileCard />
        <LinkCodeCard />
        <OpenInAppCard />
        </div>
    );
};
export default HomePage;